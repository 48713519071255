import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Home from './pages/Home';
import Header from './Components/Common/Header';
import Footer from './Components/Common/Footer';
import { useState } from 'react';
// import About from './pages/About';
// import Contact from './pages/Contact';
// import Testimonials from './pages/Testimonials';
// import Courses from './pages/Courses';

function App() {
  const [urlname, setUrlname] = useState(1);
  return (
    <BrowserRouter>
      <Helmet>
        <title>Mind Setters Academy</title>
        <meta name="description" content="Earth-friendly Quality Solutions" />
        <meta name="keywords" content="Consulting, Import, Export, Financial, Marketing" />
      </Helmet>
      <Header urlname={urlname} setUrlname={setUrlname} />
      <Routes>
        <Route exact path="/" element={<Home setUrlname={setUrlname} />} />
        {/* <Route exact path="/about-us" element={<About />} />
        <Route exact path="/courses" element={<Courses />} />
        <Route exact path="/contact-us" element={<Contact />} />
        <Route exact path="/testimonials" element={<Testimonials />} /> */}
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
