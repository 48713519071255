import React, { useState } from 'react';
import { HiOutlineArrowCircleUp } from 'react-icons/hi';

const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 500) {
      setVisible(true);
    } else if (scrolled <= 500) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  window.addEventListener('scroll', toggleVisible);

  return (
    <button className="fixed bottom-5 right-5 z-20 bg-secondary rounded-full opacity-70 hover:opacity-100">
      <HiOutlineArrowCircleUp
        onClick={scrollToTop}
        size={40}
        className={`text-white ${visible ? 'inline' : 'hidden'}`}
        // style={{ display: visible ? 'inline' : 'none' }}
      />
    </button>
  );
};

export default ScrollButton;
