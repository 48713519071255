import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
// import { Link } from 'react-router-dom';
import {
  faFacebook,
  faInstagram,
  faLinkedinIn,
  faTwitter,
  faWhatsapp
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { GrMail } from 'react-icons/gr';
import { motion } from 'framer-motion';
import { fadeIn } from '../../utils/motion';

export default function Footer() {
  return (
    <motion.footer
      variants={fadeIn('up', 'spring', 0, 0.75)}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true }}
      className="bg-light">
      <div className="max-w-7xl mx-auto px-10 py-10">
        <div className="flex flex-col md:flex-row justify-evenly gap-x-12">
          <div>
            <h6 className="uppercase font-UbuntuBold text-gray-900 text-xl border-b-4 border-main">
              Mind Setters Academy
            </h6>
            <p className="max-w-xs mt-5 text-gray-800 text-justify text-lg">
              A world of possibilities in mathematics education awaits! Meet Ashutosh, your
              passionate and dedicated mathematics teacher, who brings a wealth of expertise to the
              table. With a vision to empower young minds, Ashutosh spearheads our educational
              journey at Mindsetters, making us the go-to destination for IGCSE, A level, and IBDP
              math tutoring.
            </p>
          </div>

          <div>
            <h6 className="uppercase font-UbuntuBold text-xl pt-10 md:pt-0 text-gray-900 border-b-4 border-main">
              Contact Us
            </h6>
            <div className="flex gap-1 justify-center items-center space-y-1 mt-5">
              <FontAwesomeIcon icon={faHome} className="text-main -mt-12 lg:-mt-5" size="lg" />
              <p className="text-lg text-gray-800 max-w-[350px]">
                : 1 North Bridge Road, High Street Center, 14-04, Singapore-179094
              </p>
            </div>
            <div className="flex gap-1 items-center space-y-1">
              <FontAwesomeIcon icon={faPhone} className="text-main mt-2" size="lg" />
              <p className="text-lg text-gray-800 pl-1">:+65 9899 6939</p>
            </div>
            <div className="flex gap-1 items-center space-y-1">
              <GrMail className="text-main mt-2 text-2xl" />
              <p className="text-lg text-gray-800">: info@mindsetters.sg</p>
            </div>
          </div>

          <div className="pt-10 md:pt-0">
            <div className="">
              <h3 className="uppercase mb-5 font-UbuntuBold text-xl text-gray-900 border-b-4 border-main">
                Opening hours
              </h3>
              <ul className="list-disc list-inside text-lg text-gray-800">
                <li>
                  <span className="font-medium">Mon - Fri:</span> 11am - 8pm
                </li>
                <li>
                  <span className="font-medium">Sat - Sun:</span> 10am - 7pm
                </li>
              </ul>
            </div>

            <div>
              <h3 className="uppercase mb-5 pt-10 font-UbuntuBold text-xl text-gray-900 border-b-4 border-main">
                Connect with us
              </h3>
              <div className="flex flex-wrap gap-3 mt-3 md:justify-center">
                <a
                  href=""
                  className="cursor-pointer bg-[#0082ca] rounded-full px-2.5 py-1.5 shadow-md shadow-gray-500 hover:scale-110"
                  target="_blank"
                  rel="noreferrer">
                  <FontAwesomeIcon icon={faLinkedinIn} className="text-white text-xl" />
                </a>
                <a
                  href="mailto:info@mindsetters.sg"
                  className="cursor-pointer bg-gray-800 rounded-full px-2.5 py-1.5 shadow-md shadow-gray-500 hover:scale-110"
                  target="_blank"
                  rel="noreferrer">
                  <FontAwesomeIcon icon={faEnvelope} className="text-white" />
                </a>
                <a
                  href=""
                  className="cursor-pointer bg-[#55acee] rounded-full px-2.5 py-1.5 shadow-md shadow-gray-500 hover:scale-110"
                  target="_blank"
                  rel="noreferrer">
                  <FontAwesomeIcon icon={faTwitter} className="text-white text-xl" />
                </a>
                <a
                  href=""
                  className="cursor-pointer bg-[#3b5998] rounded-full px-2  py-1.5 shadow-md shadow-gray-500 hover:scale-110"
                  target="_blank"
                  rel="noreferrer">
                  <FontAwesomeIcon icon={faFacebook} className="text-white text-xl" />
                </a>
                <a
                  href=""
                  className="cursor-pointer bg-gradient-to-r from-purple-600 via-pink-500 to-red-500 rounded-full px-2.5 py-1.5 shadow-md shadow-gray-500 hover:scale-110"
                  target="_blank"
                  rel="noreferrer">
                  <FontAwesomeIcon icon={faInstagram} className="text-white text-xl" />
                </a>
                <a
                  href="https://api.whatsapp.com/send?phone=+917878787878&text=Hello"
                  className="cursor-pointer bg-green-500 rounded-full px-2.5 py-1.5 shadow-md shadow-gray-500 hover:scale-110"
                  target="_blank"
                  rel="noreferrer">
                  <FontAwesomeIcon icon={faWhatsapp} className="text-white text-xl" />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.footer>
  );
}
