/* eslint-disable react/prop-types */

import React from 'react';
import { motion } from 'framer-motion';
import { Tilt } from 'react-tilt';
import { fadeIn } from '../../utils/motion';

const FeatureCard = ({ index, feat }) => {
  return (
    <>
      <Tilt
        options={{
          max: 45,
          scale: 1,
          speed: 450
        }}
        className="xs:w-[250px] hidden md:block">
        <motion.div
          variants={fadeIn('right', 'spring', index * 0.5, 0.75)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}>
          <div className="flex flex-col items-center justify-evenly bg-light py-5 px-8 min-h-[280px] w-[250px] md:w-full mx-auto rounded-md shadow-lg border border-main hover:bg-medium">
            {feat?.icon}
            <h3 className="font-bold text-center text-2xl">{feat?.title}</h3>
            <p className="text-gray-700">{feat?.description}</p>
          </div>
        </motion.div>
      </Tilt>

      <div className="md:hidden z-10">
        <div className="flex flex-col items-center justify-evenly bg-light py-5 px-8 min-h-[280px] w-[250px] md:w-full mx-auto rounded-md shadow-lg border border-main hover:bg-medium">
          {feat?.icon}
          <h3 className="font-bold text-center text-2xl">{feat?.title}</h3>
          <p className="text-gray-700">{feat?.description}</p>
        </div>
      </div>
    </>
  );
};

export default FeatureCard;
