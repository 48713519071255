import { HiLightBulb } from 'react-icons/hi';
import { PiBookFill, PiExamFill } from 'react-icons/pi';
import { GiTeacher, GiBrain } from 'react-icons/gi';
import { MdTimer } from 'react-icons/md';
import { FaTools } from 'react-icons/fa';
import { BsChatTextFill, BsFillPatchQuestionFill } from 'react-icons/bs';

const about = [
  {
    title: 'Unique Approach',
    icon: <HiLightBulb />,
    description:
      "At Mindsetters, we believe in a holistic approach to learning. We don't just teach math; we inspire a genuine love for the subject. Our highly qualified educators impart knowledge with enthusiasm, creating a positive and stimulating environment that fosters intellectual growth."
  },
  {
    title: 'Comprehensive Subject Coverage',
    icon: <PiBookFill />,
    description:
      "We've got you covered! From the fundamental concepts to the most complex topics, we offer comprehensive math education across all levels. Our curriculum caters to IGCSE, A level, and IBDP syllabi, ensuring that every student receives a tailored learning experience that aligns with their academic requirements."
  },
  {
    title: 'Personalized Attention',
    icon: <GiTeacher />,
    description:
      "Small is mighty! We take pride in our small-sized group sessions and 1-on-1 interactions. This personalized attention allows our educators to understand each student's unique learning style and address their specific challenges effectively. By tailoring our teaching approach, we help students unlock their full potential and excel in mathematics."
  },
  {
    title: 'The Mindsetters Experience',
    icon: <GiBrain />,
    description:
      "At Mindsetters, it's not just about formulas and equations; it's about instilling a growth mindset. Our teaching philosophy focuses on cultivating resilience, problem-solving abilities, and confidence in our students. We go beyond the textbooks to nurture critical thinking skills and a deep understanding of mathematical concepts."
  }
];

const features = [
  {
    title: 'Key Concepts',
    icon: <BsChatTextFill size={40} className="text-main" />,
    description:
      'Master your HLs and SLs key concepts linked with questions to adapt to your learning goals.'
  },
  {
    title: 'Exam-Style Questions',
    icon: <BsFillPatchQuestionFill size={40} className="text-main" />,
    description:
      'Build a strong foundation by targeting MindSetters exam-style questions concept-by-concept.'
  },
  {
    title: 'Mock Test',
    icon: <MdTimer size={40} className="text-main" />,
    description:
      'Challenge yourself to face the real MindSetters exam-like situations and track your performance.'
  },
  {
    title: 'Predictive Paper',
    icon: <FaTools size={40} className="text-main" />,
    description:
      'Discover the areas most likely to appear on your upcoming MindSetters Examination.'
  },
  {
    title: 'Simplified Mark Scheme',
    icon: <PiExamFill size={40} className="text-main" />,
    description: 'Understand what is expected from you with structured and simplified explanations.'
  }
];

const testimonials = [
  {
    rating: 5,
    description:
      'Mind setters academy is too good for maths and physics. If you really want to make your fundamental strong. Do join this academy.',
    name: 'Bhavdip Tailor',
    position: 'Student',
    icon: './img/tiger.jpg'
  },
  {
    rating: 4.5,
    description:
      'Enrolling in Mindsetters was the best decision of my life. It helped me discover my true potential and taught me how to turn challenges into opportunities.',
    name: 'Emily Chen',
    position: 'Software Developer'
  },
  {
    rating: 5,
    description:
      'Thanks to Mindsetters Academy for their invaluable assistance, my brother excelled in his exam at the last moment, achieving outstanding results.',
    name: 'Deep Gor',
    position: 'Guardian'
  },
  {
    rating: 4,
    description:
      "Mindsetters doesn't just teach skills; it shapes leaders. The program has empowered me to lead with confidence and resilience in both my professional and personal life.",
    name: 'Michael Turner',
    position: 'Team Lead',
    icon: './img/lighthouse.jpg'
  },
  {
    rating: 4.5,
    description:
      "Mindsetters goes beyond education; it's a transformative journey. The supportive community and powerful teachings have propelled me towards success.",
    name: 'Sophia Patel',
    position: 'Student'
  }
];

const ibdp = {
  Science: [
    {
      subject: 'Science',
      name: 'IB Physics',
      numberOfStudents: 25,
      icon: 'https://images.pexels.com/photos/714698/pexels-photo-714698.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      subject: 'Science',
      name: 'IB Chemistry',
      numberOfStudents: 20,
      icon: 'https://images.pexels.com/photos/2280571/pexels-photo-2280571.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      subject: 'Science',
      name: 'IB Biology',
      numberOfStudents: 30,
      icon: 'https://images.pexels.com/photos/256262/pexels-photo-256262.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      subject: 'Science',
      name: 'IB Environmental Systems and Societies',
      numberOfStudents: 15,
      icon: 'https://images.pexels.com/photos/1108572/pexels-photo-1108572.jpeg?auto=compress&cs=tinysrgb&w=600'
    }
  ],
  SocialScience: [
    {
      name: 'IB Economics',
      numberOfStudents: 22,
      subject: 'Business & Management',
      icon: 'https://images.pexels.com/photos/730547/pexels-photo-730547.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IB Business Management',
      numberOfStudents: 18,
      subject: 'Business & Management',
      icon: 'https://images.pexels.com/photos/8190804/pexels-photo-8190804.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IB Psychology',
      numberOfStudents: 26,
      subject: 'Science',
      icon: 'https://images.pexels.com/photos/4101143/pexels-photo-4101143.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IB History',
      numberOfStudents: 27,
      subject: 'Arts & Humanities',
      icon: 'https://images.pexels.com/photos/951531/pexels-photo-951531.jpeg?auto=compress&cs=tinysrgb&w=600'
    }
  ],
  ForeignLanguages: [
    {
      name: 'IB Spanish AB',
      numberOfStudents: 16,
      subject: 'Languages',
      icon: 'https://images.pexels.com/photos/2885919/pexels-photo-2885919.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IB Spanish B',
      numberOfStudents: 12,
      subject: 'Languages',
      icon: 'https://images.pexels.com/photos/2065490/pexels-photo-2065490.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IB French AB',
      numberOfStudents: 14,
      subject: 'Languages',
      icon: 'https://images.pexels.com/photos/2272939/pexels-photo-2272939.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IB French B',
      numberOfStudents: 10,
      subject: 'Languages',
      icon: 'https://images.pexels.com/photos/1462630/pexels-photo-1462630.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IB German AB',
      numberOfStudents: 16,
      icon: 'https://images.pexels.com/photos/532864/pexels-photo-532864.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IB Mandarin AB',
      numberOfStudents: 14,
      icon: 'https://images.pexels.com/photos/2846034/pexels-photo-2846034.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IB Hindi SL',
      numberOfStudents: 10,
      icon: 'https://media.istockphoto.com/id/1044119946/photo/learn-hindi-education-and-business-background.jpg?b=1&s=612x612&w=0&k=20&c=No0b66ulIWoqPBgmStz1l112layryOlW6S4bNmWE704='
    }
  ],
  Other: [
    {
      name: 'IB Math Analysis and Approaches',
      numberOfStudents: 25,
      icon: 'https://images.pexels.com/photos/7688336/pexels-photo-7688336.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'Theory of Knowledge',
      numberOfStudents: 22,
      icon: 'https://images.pexels.com/photos/220326/pexels-photo-220326.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IB English Language and Literature',
      numberOfStudents: 28,
      icon: 'https://images.pexels.com/photos/256417/pexels-photo-256417.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IB English Literature',
      numberOfStudents: 18,
      icon: 'https://images.pexels.com/photos/6238020/pexels-photo-6238020.jpeg?auto=compress&cs=tinysrgb&w=600'
    }
  ]
};

const igcse = {
  Science: [
    {
      name: 'IGCSE Physics',
      numberOfStudents: 20,
      icon: 'https://images.pexels.com/photos/714698/pexels-photo-714698.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IGCSE Chemistry',
      numberOfStudents: 18,
      icon: 'https://images.pexels.com/photos/2280571/pexels-photo-2280571.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IGCSE Biology',
      numberOfStudents: 16,
      icon: 'https://images.pexels.com/photos/256262/pexels-photo-256262.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IGCSE Computer Science',
      numberOfStudents: 14,
      icon: 'https://images.pexels.com/photos/459653/pexels-photo-459653.jpeg?auto=compress&cs=tinysrgb&w=600'
    }
  ],
  SocialScience: [
    {
      name: 'IGCSE Economics',
      numberOfStudents: 12,
      icon: 'https://images.pexels.com/photos/730547/pexels-photo-730547.jpeg?auto=compress&cs=tinysrgb&w=600'
    }
  ],
  ForeignLanguages: [
    {
      name: 'IGCSE English Literature',
      numberOfStudents: 10,
      icon: 'https://images.pexels.com/photos/6238020/pexels-photo-6238020.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IGCSE English Language',
      numberOfStudents: 8,
      icon: 'https://images.pexels.com/photos/256417/pexels-photo-256417.jpeg?auto=compress&cs=tinysrgb&w=600'
    }
  ],
  Other: [
    {
      name: 'IGCSE Global Perspectives',
      numberOfStudents: 6,
      icon: 'https://images.pexels.com/photos/7048040/pexels-photo-7048040.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IGCSE Additional Mathematics',
      numberOfStudents: 4,
      icon: 'https://images.pexels.com/photos/1019470/abacus-mathematics-addition-subtraction-1019470.jpeg?auto=compress&cs=tinysrgb&w=600'
    },
    {
      name: 'IGCSE International Mathematics',
      numberOfStudents: 2,
      icon: 'https://images.pexels.com/photos/5212334/pexels-photo-5212334.jpeg?auto=compress&cs=tinysrgb&w=600'
    }
  ]
};

export { about, features, testimonials, ibdp, igcse };
