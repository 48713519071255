import React from 'react';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import { about, features } from '../utils/constants';
import '../index.css';
import FeatureCard from '../Components/About/FeatureCard';
import AboutRank from '../Components/About/AboutRank';
import { motion } from 'framer-motion';
import { fadeIn, textVariant } from '../utils/motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/swiper-bundle.min.css';
import { Pagination, Autoplay, EffectFlip } from 'swiper';
// import { Helmet } from 'react-helmet';

const About = () => {
  return (
    <>
      {/* <Helmet>
        <title>Mind Setters Academy - About</title>
        <meta name="description" content="About - us" />
      </Helmet> */}
      <motion.div
        variants={textVariant(0.4)}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}>
        <h2 className="text-3xl md:text-5xl text-gray-900 text-center font-UbuntuBold">
          About <span className="bg-main text-white px-2 py-1 rounded-lg">MindSetters</span>
        </h2>
      </motion.div>

      <div>
        <motion.h3
          variants={fadeIn('right', 'spring', 0.5, 0.75)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          className="text-2xl md:text-3xl text-center md:text-start font-UbuntuSemiBold my-5 pt-10">
          Feature MindSetters Provide
        </motion.h3>
        <div className="hidden md:grid md:grid-cols-3 lg:grid-cols-5 gap-5">
          {features?.map((feat, index) => (
            <FeatureCard key={feat.title} index={index} feat={feat} />
          ))}
        </div>

        <div className="md:hidden -mb-10">
          <Swiper
            spaceBetween={30}
            slidesPerView={1}
            pagination={{ clickable: true }}
            centeredSlides={true}
            // grabCursor={true}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
              pauseOnMouseEnter: true
            }}
            effect="flip"
            modules={[Pagination, Autoplay, EffectFlip]}
            className="swiper">
            {features?.map((feat, index) => (
              <SwiperSlide key={feat.title}>
                <FeatureCard key={feat.title} index={index} feat={feat} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>

      <motion.div
        variants={fadeIn('up', 'spring', 0, 0.75)}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
        className="my-20 bg-light rounded-xl py-10 border border-medium">
        <AboutRank />
      </motion.div>

      <div className="mt-10 flex flex-col">
        <h3 className="pl-4 lg:text-center text-2xl md:text-3xl font-UbuntuSemiBold underline underline-offset-[6px] decoration-main">
          More About MindSetters
        </h3>
        <VerticalTimeline className="vertical-timeline-custom-line">
          {about?.map((item, index) => (
            <VerticalTimelineElement
              key={`experience-${index}`}
              className="vertical-timeline-element--about"
              contentStyle={{
                background: '#e6e6e6',
                color: '#10b981',
                boxShadow: '0 3px #10b981'
              }}
              contentArrowStyle={{ borderRight: '10px solid  #e6e6e6' }}
              date="2019 - present"
              iconStyle={{
                background: '#e6e6e6',
                color: '#10b981',
                borderCollapse: '#10b981'
              }}
              lineColor="#10b981"
              icon={item.icon}>
              <div>
                <h3 className="font-UbuntuSemiBold text-xl md:text-2xl">{item.title}</h3>
                <p className="text-gray-700">{item.description}</p>
              </div>
            </VerticalTimelineElement>
          ))}
        </VerticalTimeline>
      </div>
    </>
  );
};

export default About;
