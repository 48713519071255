import React, { useState } from 'react';

const ContactForm = () => {
  const [form, setForm] = useState({
    name: '',
    email: '',
    phone: '',
    message: ''
  });

  // const [loading, setLoading] = useState(false);

  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value
    });
  };
  return (
    <form className="mt-8 flex flex-col gap-8">
      <label className="flex flex-col">
        <span className="text-gray-900 font-medium text-lg mb-2">Your Name :</span>
        <input
          type="text"
          name="name"
          value={form.name}
          onChange={handleChange}
          placeholder="What's your good name?"
          className="bg-light py-3 px-6 placeholder:text-gray-400 text-gray-900 rounded-lg border border-secondary outline-1 outline-secondary font-medium"
        />
      </label>
      <label className="flex flex-col">
        <span className="text-gray-900 font-medium text-lg mb-2">Your Email :</span>
        <input
          type="email"
          name="email"
          value={form.email}
          onChange={handleChange}
          placeholder="What's your email address?"
          className="bg-light py-3 px-6 placeholder:text-gray-400 text-gray-900 rounded-lg border border-secondary outline-1 outline-secondary font-medium"
        />
      </label>
      <label className="flex flex-col md:flex-row">
        <span className="text-gray-900 font-medium text-lg mb-2">Your Number :</span>
        <input
          type="number"
          name="phone"
          value={form.phone}
          onChange={handleChange}
          placeholder="What's your phone number?"
          className="bg-light py-3 px-6 md:w-[70%] md:ml-auto remove-arrow placeholder:text-gray-400 text-gray-900 rounded-lg border border-secondary outline-1 outline-secondary font-medium"
        />
      </label>
      <label className="flex flex-col">
        <span className="text-gray-900 font-medium text-lg mb-2 md:-mt-2">Your Message :</span>
        <textarea
          rows={4}
          name="message"
          value={form.message}
          onChange={handleChange}
          placeholder="What you want to say?"
          className="bg-light py-3 px-6 placeholder:text-gray-400 text-gray-900 rounded-lg border border-secondary outline-1 outline-secondary font-medium"
        />
      </label>

      <button
        type="submit"
        className="font-medium mt-2 py-2 px-10 text-lg w-fit text-white bg-secondaryDark shadow-md hover:shadow-white rounded-md hover:bg-secondaryDark">
        {/* {loading ? 'Sending...' : 'Send'} */}
        Send
      </button>
    </form>
  );
};

export default ContactForm;
