import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '../index.css';
import { faEnvelope, faHome, faPhone } from '@fortawesome/free-solid-svg-icons';
import { motion } from 'framer-motion';
import { textVariant, slideIn, staggerContainer } from '../utils/motion';
import ContactForm from '../Components/ContactForm';
// import { Helmet } from 'react-helmet';

export default function Contact() {
  return (
    <>
      {/* <Helmet>
        <title>Mind Setters Academy - Contact</title>
        <meta name="description" content="Contact Us" />
      </Helmet> */}
      <motion.div
        variants={staggerContainer()}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.25 }}
        className="max-w-7xl pt-32 mx-5 md:mx-10 lg:mx-auto overflow-hidden">
        <motion.div variants={textVariant(0.4)}>
          <h2 className="text-3xl md:text-5xl text-white text-center font-UbuntuBold">
            Contact <span className="bg-white text-main px-2 py-1 rounded-lg">MindSetters</span>
          </h2>
        </motion.div>
        <div className="mt-14 flex lg:flex-row flex-col gap-12">
          <motion.div
            variants={slideIn('left', 'tween', 0.25, 0.75)}
            className="flex-[0.80] lg:flex-[0.90] bg-gray-100 shadow-lg border-2 border-medium p-8 md:mx-12 md:p-10 lg:mx-auto rounded-2xl">
            <p className="text-center uppercase font-UbuntuSemiBold text-2xl md:text-3xl text-secondary underline decoration-main underline-offset-2">
              Get in touch
            </p>
            <ContactForm />
          </motion.div>

          <motion.div variants={slideIn('right', 'tween', 0.25, 0.75)} className="text-white">
            <div>
              <p className="text-2xl md:text-4xl mt-2 text-center font-UbuntuBold">
                Meet Mind Setters Academy At
              </p>
              <div className="bg-gray-100 md:mx-10 lg:mx-0 py-5 px-5 mt-5 shadow-md border-2 border-medium rounded-xl flex justify-center items-center">
                <div className="text-lg md:text-xl">
                  <div className="flex gap-1 items-center space-y-1">
                    <FontAwesomeIcon
                      icon={faHome}
                      className="text-secondary -mt-12 md:-mt-5"
                      size="lg"
                    />
                    <p className="text-gray-800 max-w-xs md:max-w-md lg:max-w-lg">
                      : 1 North Bridge Road, High Street Center, 14-04, Singapore-179094
                    </p>
                  </div>
                  <div className="flex gap-1 items-center space-y-2">
                    <FontAwesomeIcon icon={faPhone} className="text-secondary mt-2" size="lg" />
                    <p className="text-gray-800 pl-1">:+65 9899 6939</p>
                  </div>
                  <div className="flex gap-1 items-center space-y-2">
                    <FontAwesomeIcon icon={faEnvelope} className="text-secondary mt-2" size="lg" />
                    <p className="text-gray-800">: info@mindsetters.sg</p>
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-5">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.8071020285192!2d103.84943319999999!3d1.2900089000000001!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da190c65001d87%3A0xd99e931ebca81686!2sMind%20Setters%20Academy%2C%20Singapore!5e0!3m2!1sen!2sin!4v1699713116167!5m2!1sen!2sin"
                width="600"
                height="450"
                allowFullScreen=""
                loading="lazy"
                className="max-w-xs md:max-w-none mx-auto rounded-lg overflow-hidden border-4 border-medium"
                referrerPolicy="no-referrer-when-downgrade"></iframe>
            </div>
          </motion.div>
        </div>
      </motion.div>
    </>
  );
}
