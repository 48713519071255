import React from 'react';
import StarRating from './StarRating';
import { motion } from 'framer-motion';
import { fadeIn } from '../../utils/motion';

const FeedbackCard = ({ feedback, index }) => {
  return (
    <motion.div
      variants={fadeIn('up', 'spring', index * 0.35, 0.75)}
      initial="hidden"
      whileInView="show"
      viewport={{ once: true }}
      className="pt-12 flex justify-center items-center">
      <div className="text-center min-h-[470px] md:min-h-0 lg:min-h-[440px] md:max-w-2xl px-12 py-8 pt-20 md:mx-0 bg-gray-100 relative rounded-md">
        <div className="absolute top-0 -mt-14 left-1/2 transform -translate-x-1/2">
          <img
            src={
              feedback.icon ? feedback.icon : `https://ui-avatars.com/api/?name=${feedback?.name}`
            }
            alt=""
            className="relative rounded-full w-28 h-28 border-[8px] border-white shadow-lg"
          />
        </div>
        <div className="flex justify-center items-center mb-4">
          <StarRating rating={feedback.rating} />
        </div>
        <blockquote className="text-xl font-medium relative mb-4">
          <span className="text-5xl text-secondary absolute -top-2 -left-4">&ldquo;</span>
          {feedback.description}
          <span className="text-5xl text-secondary absolute -bottom-3">&rdquo;</span>
        </blockquote>
        <cite className="block font-bold text-lg not-italic mb-1">{feedback.name}</cite>
        <p className="text-secondary">{feedback.position}</p>
      </div>
    </motion.div>
  );
};

export default FeedbackCard;
