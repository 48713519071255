import React from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { RiMenu3Fill } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx';
import { motion } from 'framer-motion';
import { fadeIn } from '../../utils/motion';

export default function Header({ urlname, setUrlname }) {
  const [navbarOpen, setNavbarOpen] = useState(false);
  // const [urlname, setUrlname] = useState(1);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
    // setUrlname(1);
    setNavbarOpen(false);
  };

  // const navVariant = {
  //   open: {
  //     y: 0,
  //     opacity: 1,
  //     transition: {
  //       duration: 3
  //     }
  //   },
  //   closed: {
  //     y: -50,
  //     opacity: 0,
  //     transition: {
  //       y: { stiffness: 1000 }
  //     }
  //   }
  // };

  const navItem = {
    open: {
      x: 0,
      transition: {
        x: { stiffness: 100 },
        duration: 1,
        delayChildren: 0.25
      }
    },
    closed: {
      x: '100vw',
      // opacity: 0,
      transition: {
        x: { stiffness: 1000 },
        duration: 1
      }
    }
  };

  return (
    <>
      <nav className="md:flex md:justify-between px-5 lg:px-16 md:w-full bg-white fixed w-full z-10 shadow-md">
        <div className="flex flex-row justify-between items-center py-2.5 lg:py-1">
          <Link to="/" className="flex items-center">
            <motion.div
              variants={fadeIn('right', 'spring', 0, 0.75)}
              initial="hidden"
              whileInView="show"
              viewport={{ once: true }}
              onClick={scrollToTop}>
              <img src="./img/logo.jpg" className="h-[40px] md:h-12 lg:h-16 m-auto" />
            </motion.div>
          </Link>
          <motion.div
            variants={fadeIn('right', 'spring', 0, 0.75)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}>
            {navbarOpen ? (
              <RxCross2
                className="text-secondary border-2 p-[1px] border-secondary rounded md:hidden"
                size={28}
                onClick={() => setNavbarOpen(false)}
              />
            ) : (
              <RiMenu3Fill
                className="text-secondary border-2 p-[2px] border-secondary rounded md:hidden"
                size={28}
                onClick={() => setNavbarOpen(true)}
              />
            )}
          </motion.div>
        </div>
        <motion.ul
          variants={fadeIn('left', 'spring', 0, 0.75)}
          initial="hidden"
          whileInView="show"
          viewport={{ once: true }}
          className="hidden md:flex justify-between items-center gap-1">
          <li>
            <Link to="/">
              <p
                onClick={scrollToTop}
                className={
                  `${
                    urlname == 1
                      ? 'bg-secondary text-white shadow-md rounded transition duration-150 ease-in'
                      : 'text-gray-900 lg:hover:underline hover:underline-offset-2 hover:decoration-secondary'
                  } ` + 'px-6 py-2 font-UbuntuSemiBold'
                }>
                Home
              </p>
            </Link>
          </li>
          <li>
            <a href="#about">
              <p
                // onClick={() => setUrlname(2)}
                className={
                  `${
                    urlname == 2
                      ? 'bg-secondary text-white shadow-md rounded transition duration-150 ease-in'
                      : 'text-gray-900 lg:hover:underline hover:underline-offset-2 hover:decoration-secondary'
                  } ` + 'px-6 py-2 font-UbuntuSemiBold '
                }>
                About
              </p>
            </a>
          </li>
          <li>
            <a href="#courses">
              <p
                // onClick={() => setUrlname(3)}
                className={
                  `${
                    urlname == 3
                      ? 'bg-secondary text-white shadow-md rounded transition duration-150 ease-in'
                      : ' text-gray-900 lg:hover:underline hover:underline-offset-2 hover:decoration-secondary'
                  } ` + 'px-6 py-2 font-UbuntuSemiBold '
                }>
                Courses
              </p>
            </a>
          </li>
          <li>
            <a href="#testimonials">
              <p
                // onClick={() => setUrlname(4)}
                className={
                  `${
                    urlname == 4
                      ? 'bg-secondary text-white shadow-md rounded transition duration-150 ease-in'
                      : 'text-gray-900 lg:hover:underline hover:underline-offset-2 hover:decoration-secondary'
                  } ` + 'px-6 py-2 font-UbuntuSemiBold'
                }>
                Testimonials
              </p>
            </a>
          </li>
          <li>
            <a href="#contact">
              <p
                // onClick={() => setUrlname(5)}
                className={
                  `${
                    urlname == 5
                      ? 'bg-secondary text-white shadow-md rounded transition duration-150 ease-in'
                      : ' text-gray-900 lg:hover:underline hover:underline-offset-2 hover:decoration-secondary'
                  } ` + 'px-6 py-2 font-UbuntuSemiBold'
                }>
                Contact
              </p>
            </a>
          </li>
        </motion.ul>

        {/* for mobile devices */}
        <motion.ul
          animate={navbarOpen ? 'open' : 'closed'}
          variants={navItem}
          className={
            `${
              navbarOpen
                ? 'block bg-light border border-secondary shadow-lg text-center px-1 py-2 rounded-md z-10 absolute right-3 top-[50px]'
                : 'hidden'
            }` + ' md:hidden '
          }>
          <motion.li variants={navItem}>
            <Link to="/" onClick={scrollToTop}>
              <p
                className={
                  `${
                    urlname == 1
                      ? 'bg-secondary text-white font-UbuntuSemiBold'
                      : 'font-UbuntuRegular opacity-85'
                  } ` + 'border-b-[1px] px-10 py-1 text-gray-900'
                }>
                Home
              </p>
            </Link>
          </motion.li>
          <motion.li variants={navItem}>
            <a
              href="#about"
              onClick={() => {
                setUrlname(2);
                setNavbarOpen(false);
              }}>
              <p
                className={
                  `${
                    urlname == 2
                      ? 'bg-secondary text-white font-UbuntuSemiBold'
                      : 'font-UbuntuRegular opacity-85'
                  } ` + 'border-b-[1px] px-10 py-1 text-gray-900'
                }>
                About
              </p>
            </a>
          </motion.li>
          <motion.li variants={navItem}>
            <a
              href="#courses"
              onClick={() => {
                setUrlname(3);
                setNavbarOpen(false);
              }}>
              <p
                className={
                  `${
                    urlname == 3
                      ? 'bg-secondary text-white font-UbuntuSemiBold'
                      : 'font-UbuntuRegular opacity-85'
                  } ` + 'border-b-[1px] px-10 py-1 text-gray-900'
                }>
                Courses
              </p>
            </a>
          </motion.li>
          <motion.li variants={navItem}>
            <a
              href="#testimonials"
              onClick={() => {
                setUrlname(4);
                setNavbarOpen(false);
              }}>
              <p
                className={
                  `${
                    urlname == 4
                      ? 'bg-secondary text-white font-UbuntuSemiBold'
                      : 'font-UbuntuRegular opacity-85'
                  } ` + 'border-b-[1px] px-10 py-1 text-gray-900'
                }>
                Testimonials
              </p>
            </a>
          </motion.li>
          <motion.li variants={navItem}>
            <a
              href="#contact"
              onClick={() => {
                setUrlname(5);
                setNavbarOpen(false);
              }}>
              <p
                className={
                  `${
                    urlname == 5
                      ? 'bg-secondary text-white font-UbuntuSemiBold'
                      : 'font-UbuntuRegular opacity-85'
                  } ` + 'border-b-[1px] px-10 py-1 text-gray-900'
                }>
                Contact
              </p>
            </a>
          </motion.li>
        </motion.ul>
      </nav>
    </>
  );
}
