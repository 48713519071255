import { Helmet } from 'react-helmet';
import { motion } from 'framer-motion';
import { textVariant, slideIn } from '../utils/motion';
import { useState } from 'react';
import { ibdp, igcse } from '../utils/constants';
import SubjectContainer from '../Components/Course/SubjectContainer';

export default function Courses() {
  const [tab, setTab] = useState(0);
  return (
    <>
      <Helmet>
        <meta name="description" content="Products & Courses" />
      </Helmet>

      <motion.div variants={textVariant(0.4)}>
        <h2 className="text-3xl md:text-5xl md:leading-tight text-gray-900 text-center font-UbuntuBold">
          Courses offered by{' '}
          <span className="bg-main text-white px-2 py-1 rounded-lg">MindSetters</span>
        </h2>
        <p className="text-center text-gray-700 text-lg mt-5 italic">
          &ldquo;Elevate your potential with Mind Setters – where every course is a journey to
          unlock your brilliance and reshape your future!&rdquo;
        </p>
      </motion.div>

      <motion.div variants={slideIn('up', 'tween', 0.25, 0.75)}>
        <div className="border-b border-gray-200 mt-10">
          <ul className="flex flex-wrap font-medium md:text-lg text-gray-500 ">
            <li>
              <button
                onClick={() => {
                  setTab(0);
                }}
                className={
                  tab === 0
                    ? 'inline-flex p-3 text-secondary border-b-2 border-secondary rounded-t-lg active group'
                    : 'inline-flex p-3 text-gray-500 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 group'
                }>
                IBDP
              </button>
            </li>
            <li>
              <button
                onClick={() => {
                  setTab(1);
                }}
                className={
                  tab === 1
                    ? 'inline-flex p-3 text-secondary border-b-2 border-secondary rounded-t-lg active group'
                    : 'inline-flex p-3 text-gray-500 border-b-2 border-transparent rounded-t-lg hover:text-gray-600 hover:border-gray-300 group'
                }>
                IGCSE
              </button>
            </li>
          </ul>
        </div>

        {tab === 0 && (
          <div className="bg-gray-50">
            <div className="pt-10">
              <SubjectContainer data={ibdp.Science} subject={'Science'} />
            </div>
            <div className="mt-10">
              <SubjectContainer data={ibdp.SocialScience} subject={'Social Science'} />
            </div>
            <div className="mt-10">
              <SubjectContainer data={ibdp.ForeignLanguages} subject={'Foreign Languages'} />
            </div>
            <div className="mt-10 lg:mt-5 pb-20">
              <SubjectContainer data={ibdp.Other} subject={'Other'} />
            </div>
          </div>
        )}

        {tab === 1 && (
          <div className="bg-gray-50">
            <div className="pt-10">
              <SubjectContainer data={igcse.Science} subject={'Science'} />
            </div>
            <div className="mt-10">
              <SubjectContainer data={igcse.SocialScience} subject={'Social Science'} />
            </div>
            <div className="mt-10">
              <SubjectContainer data={igcse.ForeignLanguages} subject={'Foreign Languages'} />
            </div>
            <div className="pt-3 pb-20">
              <SubjectContainer data={igcse.Other} subject={'Other'} />
            </div>
          </div>
        )}
      </motion.div>
    </>
  );
}
