import React, { useEffect } from 'react';
import HomeImage from '../Components/Home/HomeImage';
import About from './About';
import Contact from './Contact';
import Testimonials from './Testimonials';
import Courses from './Courses';
import { motion } from 'framer-motion';
import { textVariant } from '../utils/motion';
import ScrollButton from '../Components/Common/ScrollButton';
import ScrollTrigger from 'react-scroll-trigger';

export default function Home({ setUrlname }) {
  useEffect(() => {
    const unlisten = () => {
      window.scrollTo(0, 0);
    };
    return () => {
      unlisten();
    };
  }, []);

  return (
    <main className="h-full font-UbuntuRegular">
      <ScrollTrigger onEnter={() => setUrlname(1)}>
        <section className="bg-main pb-10">
          <motion.div
            variants={textVariant(0.4)}
            initial="hidden"
            whileInView="show"
            viewport={{ once: true }}
            className="pt-48 mx-5 md:mx-20 lg:mx-40 flex flex-col justify-center items-center gap-8">
            <h1 className="text-3xl md:text-5xl lg:text-7xl bg-clip-text font-UbuntuBold tracking-tighter text-white">
              Welcome to Mindsetters
            </h1>
            <p className="text-xl lg:text-3xl text-center font-medium text-white tracking-tight">
              &ldquo;Unlock Your Potential with Mindsetters: Where Learning Meets Limitless
              Possibilities!&rdquo;
            </p>
            <a href="#courses">
              <button className="font-medium mt-2 py-2 px-5 md:px-10 text-lg text-white bg-secondaryDark shadow-md hover:shadow-white rounded-md hover:bg-secondaryDark">
                Courses offered by Mind Setters
              </button>
            </a>
          </motion.div>
          <HomeImage />
        </section>
      </ScrollTrigger>

      <section id="about" className="max-w-7xl pt-32 mx-5 md:mx-10 lg:mx-auto">
        <ScrollTrigger onEnter={() => setUrlname(2)}>
          <About />
        </ScrollTrigger>
      </section>

      <ScrollTrigger onEnter={() => setUrlname(3)}>
        <motion.section
          id="courses"
          initial="hidden"
          whileInView="show"
          viewport={{ once: true, amount: 0.2 }}
          className="max-w-7xl pt-32 mx-5 md:mx-10 lg:mx-auto">
          <Courses />
        </motion.section>
      </ScrollTrigger>

      <section id="testimonials" className="max-w-7xl pt-32 mx-5 md:mx-10 lg:mx-auto relative">
        <ScrollTrigger onEnter={() => setUrlname(4)}>
          <Testimonials />
        </ScrollTrigger>
      </section>

      <ScrollTrigger onEnter={() => setUrlname(5)}>
        <section id="contact" className="bg-main pb-32">
          <Contact />
        </section>
      </ScrollTrigger>
      <ScrollButton />
    </main>
  );
}
