import React from 'react';
import { motion } from 'framer-motion';
import { textVariant } from '../utils/motion';
import FeedbackCard from '../Components/Testimonial/FeedbackCard';
import { testimonials } from '../utils/constants';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/swiper-bundle.min.css';
import '../index.css';
import { Pagination, Autoplay, EffectFade, Navigation } from 'swiper';

const Testimonials = () => {
  return (
    <>
      <div
        className="hidden lg:hidden md:block absolute left-0 transform -translate-x-[13%] bottom-0 pointer-events-none"
        aria-hidden="true">
        <svg width="118%" height="718" viewBox="0 0 1760 518" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-02">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g transform="translate(0 -3)" fill="url(#illustration-02)" fillRule="evenodd">
            <circle cx="1630" cy="-40%" r="128" />
            <circle cx="200" cy="160%" r="80" />
          </g>
        </svg>
      </div>

      <div
        className="hidden lg:block absolute left-0 transform -translate-x-[15%] bottom-0 pointer-events-none"
        aria-hidden="true">
        <svg width="120%" height="718" viewBox="0 0 1760 518" xmlns="http://www.w3.org/2000/svg">
          <defs>
            <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="illustration-01">
              <stop stopColor="#FFF" offset="0%" />
              <stop stopColor="#EAEAEA" offset="77.402%" />
              <stop stopColor="#DFDFDF" offset="100%" />
            </linearGradient>
          </defs>
          <g transform="translate(0 -3)" fill="url(#illustration-01)" fillRule="evenodd">
            <circle cx="1630" cy="3%" r="128" />
            <circle cx="178" cy="510" r="50" />
          </g>
        </svg>
      </div>

      <motion.div
        variants={textVariant(0.4)}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true, amount: 0.2 }}>
        <h2 className="text-3xl md:text-5xl md:pt-20 lg:pt-0 text-gray-900 text-center font-UbuntuBold">
          <span className="bg-main text-white px-2 py-1 rounded-lg">Testimonials</span>
        </h2>
        <p className="text-center text-gray-700 text-lg mt-5 md:pb-10 lg:pb-0 italic">
          &ldquo;Don&apos;t just take our word for it – listen to the inspiring tales of growth and
          achievement straight from our Mindsetter family!&rdquo;
        </p>
      </motion.div>

      <div className="hidden lg:block py-20 mx-5 mb-10">
        <Swiper
          spaceBetween={30}
          slidesPerView={3}
          navigation
          rewind={true}
          pagination={{ clickable: true }}
          modules={[Navigation, Pagination]}
          className="swiper">
          {testimonials?.map((feedback, index) => (
            <SwiperSlide key={`feedback-${index}`}>
              <FeedbackCard feedback={feedback} index={index} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      <div className="lg:hidden py-10 md:py-28 mb-5">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          centeredSlides={true}
          // grabCursor={true}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}
          effect="fade"
          modules={[Pagination, Autoplay, EffectFade]}
          className="swiper">
          {testimonials?.map((feedback, index) => (
            <SwiperSlide key={`feedback-${index}`}>
              <FeedbackCard feedback={feedback} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Testimonials;
