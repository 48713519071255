import React from 'react';
import { IoIosPeople } from 'react-icons/io';

const CourseCard = ({ course }) => {
  return (
    <div>
      <div className="w-auto mx-5 h-[305px] bg-white border border-gray-200 rounded-lg shadow lg:hover:scale-105">
        <img
          className="rounded-t-lg w-full h-44 object-center"
          src={course.icon}
          alt="Mind Setters course"
        />

        <div className="p-5">
          <h5 className="mb-2 text-xl font-UbuntuSemiBold tracking-tight text-gray-900">
            {course.name}
          </h5>

          <div className="flex items-center gap-2">
            <IoIosPeople size={28} className="text-main drop-shadow" />
            <p className="font-normal text-secondary">{course.numberOfStudents} students</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CourseCard;
