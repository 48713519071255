import React from 'react';
import CourseCard from './CourseCard';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/swiper-bundle.min.css';
import '../../index.css';
import { Pagination, Autoplay, EffectFade } from 'swiper';

const SubjectContainer = ({ data, subject }) => {
  return (
    <div>
      <h3 className="text-2xl px-5 md:text-3xl text-center border-b-2 border-main mx-[10%] md:mx-[28%] lg:mx-[38%] font-UbuntuSemiBold my-2">
        {subject}
      </h3>

      {subject === 'Foreign Languages' ? (
        <div className="hidden lg:block px-5 pt-2">
          <Swiper
            // spaceBetween={30}
            slidesPerView={4}
            // navigation
            grabCursor={true}
            // rewind={true}
            pagination={{ clickable: true }}
            modules={[Pagination]}
            className="swiper">
            {data?.map((course, id) => (
              <SwiperSlide key={`${subject}-${id}`}>
                <CourseCard course={course} />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      ) : (
        <div className="hidden lg:grid lg:grid-cols-4 px-5 pt-2">
          {data?.map((course, id) => (
            <CourseCard key={`${subject}-${id}`} course={course} />
          ))}
        </div>
      )}

      {/* For mobile view */}
      <div className="md:hidden py-3 flex justify-center">
        <Swiper
          spaceBetween={30}
          slidesPerView={1}
          pagination={{ clickable: true }}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}
          effect="fade"
          modules={[Pagination, Autoplay, EffectFade]}
          className="swiper">
          {data?.map((course, id) => (
            <SwiperSlide key={`${subject}-${id}`}>
              <CourseCard course={course} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>

      {/* Tab View  */}
      <div className="hidden md:block lg:hidden px-5 pt-2">
        <Swiper
          // spaceBetween={30}
          slidesPerView={2}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
            pauseOnMouseEnter: true
          }}
          pagination={{ clickable: true }}
          modules={[Pagination, Autoplay]}
          className="swiper">
          {data?.map((course, id) => (
            <SwiperSlide key={`${subject}-${id}`}>
              <CourseCard course={course} />
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default SubjectContainer;
