import React, { useState } from 'react';
import CountUp from 'react-countup';
import ScrollTrigger from 'react-scroll-trigger';

const AboutRank = () => {
  const [counterOn, setCounterOn] = useState(false);
  return (
    <ScrollTrigger onEnter={() => setCounterOn(true)}>
      <h2 className="text-gray-900 font-UbuntuBold text-xl md:text-3xl text-center">
        Ranked{' '}
        <span className="underline underline-offset-2 decoration-mainDark">#1MindSetters</span>{' '}
        Aligned Resource in 2022{' '}
      </h2>
      <p className="text-gray-700   text-lg text-center pt-5 pb-8">
        Trusted by MindSetters Students, Teachers and Examiners globally
      </p>
      <div className="flex flex-col justify-center md:flex-row md:flex-wrap md:justify-between gap-8 md:mx-20">
        <div className="text-center">
          <h3 className="text-gray-900 text-3xl md:text-4xl font-UbuntuBold mb-3">
            {counterOn && <CountUp start={0} end={90} duration={2} />}%
          </h3>
          <span className="bg-main text-white px-3 py-2 rounded-full md:text-lg shadow-md shadow-mainDark">
            Grade Increase
          </span>
        </div>
        <div className="text-center">
          <h3 className="text-gray-900 text-3xl md:text-4xl font-UbuntuBold mb-3">
            {counterOn && <CountUp start={0} end={8559} duration={2} />}+
          </h3>
          <span className="bg-main text-white px-3 py-2 rounded-full md:text-lg shadow-md shadow-mainDark">
            Questions Practiced
          </span>
        </div>
        <div className="text-center">
          <h3 className="text-gray-900 text-3xl md:text-4xl font-UbuntuBold mb-3">
            {counterOn && <CountUp start={0} end={6349} duration={2} />}+
          </h3>
          <span className="bg-main text-white px-8 py-2 rounded-full md:text-lg shadow-md shadow-mainDark">
            Tests Taken
          </span>
        </div>
        <div className="text-center">
          <h3 className="text-gray-900 text-3xl md:text-4xl font-UbuntuBold mb-3">
            {counterOn && <CountUp start={0} end={7000} duration={2} />}+
          </h3>
          <span className="bg-main text-white px-3 py-2 rounded-full md:text-lg shadow-md shadow-mainDark">
            Assignment Completed
          </span>
        </div>
        <div className="text-center">
          <h3 className="text-gray-900 text-3xl md:text-4xl font-UbuntuBold mb-3">
            {counterOn && <CountUp start={0} end={7550} duration={2} />}+
          </h3>
          <span className="bg-main text-white px-3 py-2 rounded-full md:text-lg shadow-md shadow-mainDark">
            Key Concepts Viewed
          </span>
        </div>
      </div>
    </ScrollTrigger>
  );
};

export default AboutRank;
