import React from 'react';
import { motion } from 'framer-motion';
import { zoomIn } from '../../utils/motion';
import './HomeImage.css';

const HomeImage = () => {
  return (
    <section className="py-5 pb-10">
      <motion.div
        variants={zoomIn(0.25, 0.75)}
        initial="hidden"
        whileInView="show"
        viewport={{ once: true }}
        className="mx-4 md:mx-10 lg:mx-48 py-5 rounded-xl bg-main">
        <div className="relative py-10 md:py-20">
          <div className="flex justify-center">
            <img src="./img/logo.jpg" className="w-[60%] lg:h-[320px] rounded-full" />
          </div>
          <div className="chemistryContainer ">
            <img src="./img/chem.png" />
          </div>
          <div className="physicsContainer ">
            <img src="./img/physics.png" />
          </div>
          <div className="businessContainer ">
            <img src="./img/economics.png" />
          </div>
          <div className="mathContainer ">
            <img src="./img/maths.png" />
          </div>
          <div className="biologyContainer ">
            <img src="./img/biology.png" />
          </div>
          <div className="csContainer ">
            <img src="./img/cs.png" />
          </div>
        </div>
      </motion.div>
    </section>
  );
};

export default HomeImage;
